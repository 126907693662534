<template>
  <v-card
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div v-if="tool.desc">
          <span
            class="text-grey px-1 rounded-xl py-0.5"
            :class="(qtyUsed) >= tool.stock ? 'bg-info' : 'bg-success'"
          >
            {{ (qtyUsed) >= tool.stock ? 'OCUPADO' : tool.single ? 'DISPONIBLE' : `DISPONIBLE (${tool.stock - qtyUsed})` }}
          </span>
        </div>
        <v-list-item-title class="text-h5 mb-1 capitalize">
          {{ tool.code }}
        </v-list-item-title>
        <v-list-item-subtitle class="capitalize">
          {{ tool.desc }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="150"
        color="grey"
      >
        <img
          alt="Avatar"
          :src="tool.img ? tool.img[0] : ''"
        >
      </v-list-item-avatar>
    </v-list-item>
    <v-card-actions>
      <v-btn
        outlined
        rounded
        text
        @click="$emit('edit', keyItem)"
      >
        Editar
      </v-btn>
      <v-btn
        outlined
        rounded
        text
        @click="$emit('history', keyItem)"
      >
        Historial
      </v-btn>
      <v-btn
        v-if="tool.in_use"
        outlined
        rounded
        text
        @click="reveal = true"
      >
        En uso
      </v-btn>
    </v-card-actions>
    <!-- Expansion -->
    <v-expand-transition>
      <v-card
        v-if="reveal"
        class="v-card--reveal"
        style="height: 100%;"
      >
        <v-card-text class="pb-0">
          <p class="text-h4 text--primary">
            En uso por:
          </p>
          <p
            v-for="(item, key) in tool.in_use"
            :key="key"
          >
            {{ getIdUser(item) }}
          </p>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            variant="text"
            color="teal-accent-4"
            @click="reveal = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import usersOld from './usersOld.json'

export default {
  name: 'ItemBodegas',
  props: {
    tool: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      reveal: false,
    }
  },
  computed: {
    keyItem: {
      get() {
        return this.$vnode.key
      },
    },
    qtyUsed:{
      get(){
        if (this.tool.in_use){
          return this.tool.in_use.length
        }
        return 0
      }
    }
  },
  methods: {
    getIdUser(user) {
      const stored = localStorage.users
      let name = ''
      let listUsers = ''
      if (stored) {
        listUsers = JSON.parse(stored)
      }
      const jsonUsers = usersOld
      if (user.length > 6) {
        const idUser = jsonUsers[user]
        name = listUsers[idUser]
        return `${idUser} - ${name}`
      }
      return `${user} - ${listUsers[user]}`
    },
  },
}
</script>
