<template>
  <div
    data-app
  >
    <v-dialog
      v-model="showModel"
      width="auto"
    >
      <template v-if="information.tool">
        <v-container>
          <v-row justify="space-around">
            <v-card width="400">
              <v-img
                v-if="information.tool.img"
                height="200"
                :src="information.tool.img[0]"
                cover
                class="text-white"
              />

              <v-card-text data-app>
                <h2>{{ information.tool.desc }}</h2>
                <div
                  v-for="(element, key) in information.history"
                  :key="key"
                  class="item-history shadow"
                  :class="element.action"
                >
                  <div>
                    <h4 class="bold text-white uppercase">{{ element.action }}</h4>
                    <p v-if="element.comment"><b>Comentario:</b> {{ element.comment }}</p>
                    <p><b>Fecha:</b> {{ element.date }}</p>
                    <p><b>Colaborador:</b> {{ element.colab }}</p>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-row>
        </v-container>
      </template>
    </v-dialog>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'show',
    event: 'update:show',
  },
  props: {
    information: {
      type: Object,
      required: false,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: true,
      default: () => {},
    },
  },
  computed: {
    showModel: {
      get() {
        return this.show
      },
      set(newValue) {
        // $emit is the correct way to update props:
        this.$emit('update:show', newValue)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.item-history{
  margin-bottom: 1rem;
  border-radius: 8px;
  background: #cacaca;
  padding: 1.5rem;
  p {
    margin-bottom: 0;
    font-size: 15px;
  }
  h4{
    text-align: right;
  }
}
.retiro, .Retiro, .RETIRO {
    background: #c12d2d;
    color: #efefef;
  }
  .ingreso, .Ingreso, .INGRESO {
    background: #3e913e;
    color: #efefef;
  }
</style>
