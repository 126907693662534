<template>
  <div>
    <b-card>
      <div class="flex">
        <b-form-input
          id="filterInput"
          v-model="search"
          type="search"
          placeholder="Buscar Herramienta..."
        />
        <b-button
          v-if="myRole === 'master' || myRole === 'bodegas'"
          class="ml-1 btn-icon rounded-circle"
          variant="success"
          @click="addTool"
        >
          <feather-icon icon="FilePlusIcon" />
        </b-button>
        <b-button
          v-if="myRole === 'master' || myRole === 'bodegas'"
          class="ml-1 btn-icon rounded-circle"
          variant="success"
          @click="$bvModal.show('users-tools-modal')"
        >
          <feather-icon icon="UserPlusIcon" />
        </b-button>
        <b-button
          class="ml-1"
          variant="primary"
          @click="$bvModal.show('tools-control-modal'); viewMyTools = true; backTool = false"
        >
          Usuario
        </b-button>
        <b-button
          class="ml-1"
          variant="primary"
          @click="$bvModal.show('tools-control-modal'); backTool = true; viewMyTools = false"
        >
          Devolución
        </b-button>
        <b-button
          class="ml-1"
          variant="primary"
          @click="$bvModal.show('tools-control-modal'); backTool = false; viewMyTools = false"
        >
          Retiro
        </b-button>
      </div>

    </b-card>
    <b-row>
      <template v-for="(tool, i) in rawAllTools">
        <b-col
          v-show="exist(tool.desc)"
          :key="i"
          xl="3"
          md="4"
          class="card-tool"
        >
          <item
            :key="i"
            :tool="tool"
            :rol="myRole"
            @history="showHistory"
            @edit="editTool"
          />
        </b-col>
      </template>
    </b-row>
    <!-- MODALS -->
    <modal
      :tool="objToEdit"
    />
    <control-modal
      v-if="allUsers"
      :tools="rawAllTools"
      :users="allUsers"
      :is-return="backTool"
      :only-view="viewMyTools"
      :update-data.sync="updateRaw"
    />
    <users-modal
      v-if="allUsers"
      :users="allUsers"
    />
    <history
      :information="dataHistory"
      :show.sync="dataHistory.show"
    />
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton, BFormInput,
} from 'bootstrap-vue'
import firebase from 'firebase/app'
import modal from './Components/Modal.vue'
import ControlModal from './Components/Controlv2.vue'
import UsersModal from './Components/Usersv2.vue'
import Item from './Components/Item.vue'
import History from './Components/History.vue'

export default {
  components: {
    BCard, BCol, BRow, modal, BButton, ControlModal, BFormInput, UsersModal, Item, History,
  },
  data() {
    return {
      newtool: {
        desc: '',
        oldcode: '',
        in_use: 0,
        marca: '',
        size: '',
        modelo: '',
        cat: '',
        ubi: '',
        code: '',
        stock: 1,
        single: true,
        img: [],
      },
      rawAllTools: null,
      updateRaw: false,
      objToEdit: {},
      search: null,
      allUsers: null,
      backTool: false,
      viewMyTools: false,
      // Role options
      myRole: null,
      // History
      dataHistory: {
        show: false,
        tool: null,
        history: null,
      },
    }
  },
  mounted() {
    this.myRole = this.$route.meta.currentUserRol
    firebase.database().ref('bodegas/herramientas').once('value', snap => {
      const result = snap.val()
      this.rawAllTools = JSON.parse(JSON.stringify(result))
    })
    firebase.database().ref('bodegas/usrs').on('value', snap => {
      this.allUsers = snap.val()
      localStorage['users'] = JSON.stringify(snap.val())
    })
    firebase.database().ref('bodegas/herramientas').on('child_changed', snap => {
      const itemChange = snap.val()
      const snapKey = snap.key
      this.rawAllTools[snapKey] = JSON.parse(JSON.stringify(itemChange))
      const currentRaw = this.rawAllTools
      this.rawAllTools = JSON.parse(JSON.stringify(currentRaw))
      this.updateRaw = true
    })
  },
  methods: {
    editTool(event) {
      this.myRole = this.$route.meta.currentUserRol
      const obj = this.rawAllTools[event]
      obj.key = event
      if ((this.myRole === 'master') || (this.myRole === 'bodegas')) {
        this.objToEdit = JSON.parse(JSON.stringify(obj))
        this.$bvModal.show('tools-modal')
      }
    },
    addTool() {
      this.objToEdit = JSON.parse(JSON.stringify(this.newtool))
      this.$bvModal.show('tools-modal')
    },
    exist(name) {
      const nombre = name.toUpperCase()
      const busqueda = this.search ? this.search.toUpperCase() : ''
      if (busqueda) {
        if (nombre.indexOf(busqueda) !== -1) {
          return true
        }
        return false
      }
      return true
    },
    showHistory(event) {
      firebase.database().ref(`history/${event}`).on('value', snap => {
        this.dataHistory.history = snap.val()
        this.dataHistory.tool = this.rawAllTools[event]
        this.dataHistory.show = true
      })
    },
  },
}
</script>
<style>
.card-tool img{
  height: 180px;
  object-fit: cover;
}
.card-tool .v-card{
  cursor: pointer;
}
</style>
